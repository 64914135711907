import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const getData = graphql`
  {
    mainLogo: wordpressAcfOptions {
      options {
        swb_theme_main_logo {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

const NavLogo = styled.div`
  width: 100%;
  max-width: 25rem;
  margin: 2rem auto 4rem;
`

const MobileNavLogo = () => {
  const data = useStaticQuery(getData)
  const { mainLogo } = data
  return (
    <NavLogo>
      <Link to="/">
        {" "}
        <Img
          fluid={
            mainLogo.options.swb_theme_main_logo.localFile.childImageSharp.fluid
          }
          alt={mainLogo.options.swb_theme_main_logo.alt_text}
        />
      </Link>
    </NavLogo>
  )
}

export default MobileNavLogo
