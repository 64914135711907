const themeColors = {
  colorPrimary: "rgba(217, 199, 86, 1)",
  colorSecondary: "rgba(0, 134, 117, 1)",
  colorTertiary: "rgba(78, 91, 49, 1)",
  colorAccent: "rgba(197, 231, 222, 1)",
  colorShad: "rgba(124, 38, 41, 1)",
  colorAlt: "rgba(229, 226, 204, 1)",
  white: "rgba(255,255,255, 1)",
  grey: "rgba(226,232,233, 1)",
  greyMed: "rgba(120,119,124, 1)",
  greyBrown: "rgba(70,70,70, 1)",
  black: "rgba(0,0,0, 1)",
  strongred: "rgba(255, 0, 0, 1)",
}

export default themeColors
