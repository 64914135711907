import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Img from "gatsby-image"
import styled from "styled-components"

const getData = graphql`
  {
    mainLogo: wordpressAcfOptions {
      options {
        swb_theme_main_logo {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        swb_theme_main_logo_rev {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`

const HeaderLogoSection = styled.div`
  width: 100%;

  @media (min-width: 1025px) {
    width: calc(20%);
  }

  .siteTitle {
    position: absolute;
    left: -99999px;
    font-size: 1rem;
  }

  a {
    display: block;
    max-width: 20rem;
    margin: auto;

    @media (min-width: 768px) {
      max-width: 21rem;
      margin-bottom: 2.5rem;
    }
    @media (min-width: 1024px) {
      max-width: 21rem;
      margin-bottom: 0;
    }
  }
`

const HeaderLogo = props => {
  const { positionAbs } = props
  const data = useStaticQuery(getData)
  const { mainLogo } = data

  const mainLogoDisplay = positionAbs ? (
    <Img
      fluid={
        mainLogo.options.swb_theme_main_logo.localFile.childImageSharp.fluid
      }
      alt={mainLogo.options.swb_theme_main_logo.alt_text}
    />
  ) : (
    <Img
      fluid={
        mainLogo.options.swb_theme_main_logo_rev.localFile.childImageSharp.fluid
      }
      alt={mainLogo.options.swb_theme_main_logo_rev.alt_text}
    />
  )

  return (
    <HeaderLogoSection>
      <h1>
        <AniLink paintDrip hex="#000" to="/">
          {mainLogoDisplay}
        </AniLink>
        <span className="siteTitle">Cooper's Crossing in Airdrie Alberta</span>
      </h1>
    </HeaderLogoSection>
  )
}

export default HeaderLogo
