import React from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { createSlug } from "../../utils/helperFunc"
import { navOneWhite, colors } from "../../styles/helpers"
import HeaderNavBotSubMenu from "./HeaderNavBotSubMenu"

const ListItem = styled.li`
  position: relative;
  margin: auto 1rem;

  @media (min-width: 768px) {
    ${props => (props.lastItem ? "margin-right: 0" : null)};
  }

  @media (min-width: 1200px) {
    margin: auto 1rem;
  }

  a {
    ${navOneWhite};
    padding: 0.5rem;
    color: ${props => (props.positionAbs ? colors.white : colors.greyBrown)};
    border-bottom: ${props =>
      props.currentPage
        ? "solid 3px rgba(197, 231, 222, 1)"
        : "solid 0px rgba(197, 231, 222, 1)"};
    text-transform: uppercase;
  }

  &:hover {
    .main-nav-sub {
      visibility: visible;
      opacity: 1;
    }
  }
`

const HeaderNavBottomItem = ({ item, index, total, positionAbs, location }) => {
  const itemSlug = createSlug(item.url)
  const itemSlugDisplay = itemSlug === "/" ? "" : itemSlug

  const lastItem = index + 1 === total ? true : false

  const subMenuReq =
    item.wordpress_children !== null
      ? item.wordpress_children.length > 0
        ? true
        : false
      : false

  const subMenu = subMenuReq ? (
    <HeaderNavBotSubMenu
      location={location}
      subItems={item.wordpress_children}
    />
  ) : null

  const currentPage = location === `/${itemSlugDisplay}`

  return (
    <ListItem
      currentPage={currentPage}
      lastItem={lastItem}
      positionAbs={positionAbs}
    >
      <AniLink cover direction="up" bg="#000" to={`/${itemSlugDisplay}`}>
        {item.title}
      </AniLink>
      {subMenu}
    </ListItem>
  )
}

export default HeaderNavBottomItem
