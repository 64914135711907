import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import HeaderLogo from "./HeaderLogo"
import HeaderNav from "./HeaderNav"
import { BigWrapper } from "../../styles/helpers"

const HeaderSection = styled.header`
  position: ${props => (props.positionAbs ? "absolute" : "relative")};
  top: ${props => (props.positionAbs ? "0" : "auto")};
  left: ${props => (props.positionAbs ? "0" : "auto")};
  width: 100%;
  margin-top: ${props => (props.positionAbs ? "2rem" : "0")};
  padding-top: ${props => (props.positionAbs ? "0" : "2rem")};
  background: ${props =>
    props.positionAbs
      ? "transparent"
      : "linear-gradient(to bottom, rgba(229, 226, 204, 1), rgba(128,128,128, 0))"};
  z-index: 999999;

  .wrapper {
    ${BigWrapper};
    align-items: center;
  }
`

const Header = ({ siteTitle, location }) => {
  const positionAbs =
    location === "/" ||
    location === "/community-life" ||
    location === "/connect" ||
    location === "/westmark-holdings" ||
    location === "/show-homes/builders-info" ||
    location === "/community-life/faqs" ||
    location === "/news" ||
    location === "/gallery"
      ? true
      : false

  return (
    <HeaderSection id="pagetop" positionAbs={positionAbs}>
      <div className="wrapper">
        <HeaderLogo positionAbs={positionAbs} />
        <HeaderNav positionAbs={positionAbs} location={location} />
      </div>
    </HeaderSection>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
