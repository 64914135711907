import React from "react"
import styled from "styled-components"
import { colors } from "../../styles/helpers"

import HeaderNavBotSubMenuItem from "./HeaderNavBotSubMenuItem"

const SubMenu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  min-width: 25rem;
  margin: 0 auto;
  padding: 1rem;
  transition: all 0.3s ease;
  transform: translateX(-50%);
  background-color: ${colors.grey};
  visibility: hidden;
  opacity: 0;
  z-index: 999999;
`

const HeaderNavBotSubMenu = ({ location, subItems }) => {
  return (
    <SubMenu className="main-nav-sub">
      {subItems.map(item => {
        return (
          <HeaderNavBotSubMenuItem
            location={location}
            key={item.wordpress_id}
            item={item}
          />
        )
      })}
    </SubMenu>
  )
}

export default HeaderNavBotSubMenu
