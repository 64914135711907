import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"
import { fontSizer, colors, fonts } from "../../styles/helpers"
import { createSlug } from "../../utils/helperFunc"

const SubItem = styled.li`
  ${fontSizer(1.2, 1.3, 76.8, 150, 1.4)}
  width: 100%;

  a.sub-menu-link {
    display: block;
    width: 100%;
    padding: 1rem 0.5rem;
    border-bottom: 0.2rem solid ${colors.white};
    background: ${props =>
      props.currentPage ? "rgba(217, 199, 86, 1)" : "transparent"};
    color: ${colors.greyBrown};
    font-family: ${fonts.fontPrimary};
    font-size: 1em;
    font-weight: bold;

    &:hover {
      color: ${props =>
        props.currentPage
          ? props.theme.greyBrown
          : props => props.theme.colorPrimary};
      background: ${props =>
        props.currentPage
          ? props.theme.colorPrimary
          : props => props.theme.colorSecondary};
    }
  }
`

const HeaderNavBotSubMenuItem = ({ location, item }) => {
  const slug = createSlug(item.url)
  const currentPage = location === `/${slug}`

  return (
    <SubItem currentPage={currentPage}>
      <AniLink
        cover
        direction="up"
        bg="#000"
        className="sub-menu-link"
        to={`/${slug}`}
      >
        {item.title}
      </AniLink>
    </SubItem>
  )
}

export default HeaderNavBotSubMenuItem
