import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { colors, fontSizer } from "../styles/helpers"
import scrollTo from "gatsby-plugin-smoothscroll"

const BackToTopStyled = styled.div`
  position: fixed;
  bottom: 10rem;
  right: 5rem;
  width: 5rem;
  height: 5rem;
  transition: all 0.3s ease;
  transform: ${props =>
    props.showButton ? "translateX(0%)" : "translateX(500%)"};
  border-radius: 50%;
  box-shadow: 0rem 0.3rem 1rem rgba(0, 0, 0, 0.25);
  overflow: hidden;
  z-index: 1000;

  button {
    ${fontSizer(1.4, 1.6, 76.8, 150, 1.8)};
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease;
    border: none;
    background: none;
    background-color: ${colors.colorAccent};
    border-radius: 50%;
    color: ${colors.black};
    text-transform: uppercase;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      background-color: ${colors.colorPrimary};
      color: ${colors.white};
    }

    &:focus {
      outline: none;
    }
  }
`

const BackToTop = () => {
  const [showButton, setShowButton] = useState(false)
  useEffect(() => {
    document.addEventListener("scroll", function (e) {
      if (window.scrollY > 500 && !showButton) {
        setShowButton(true)
      } else if (window.scrollY <= 500) {
        setShowButton(false)
      }
    })
  }, [])
  return (
    <BackToTopStyled showButton={showButton}>
      <button type="button" onClick={() => scrollTo("#pagetop")}>
        Top
      </button>
    </BackToTopStyled>
  )
}

export default BackToTop
