import React from "react"
import styled from "styled-components"
import HeaderNavTopItem from "./HeaderNavTopItem"

const HeaderNavTopSection = styled.nav`
  width: 100%;
  margin-bottom: 1rem;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: 1025px) {
      justify-content: flex-end;
    }

    li:last-of-type {
      margin-right: 0 !important;

      a {
        padding-right: 0 !important;
      }
    }
  }
`

const HeaderNavTop = ({ location, topMenuItems, positionAbs }) => {
  return (
    <HeaderNavTopSection>
      <ul>
        {topMenuItems.map((item, index) => {
          const noPipe = index + 1 === topMenuItems.length

          return (
            <HeaderNavTopItem
              key={item.wordpress_id}
              item={item}
              noPipe={noPipe}
              location={location}
              positionAbs={positionAbs}
            />
          )
        })}
      </ul>
    </HeaderNavTopSection>
  )
}

export default HeaderNavTop
