import React from "react"
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import { createSlug } from "../../utils/helperFunc"
import { navOneWhite, colors, fontSizer } from "../../styles/helpers"

const ListItem = styled.li`
  margin: auto 1rem;
  position: relative;

  &::before {
    display: ${props => (props.noPipe ? "none" : "block")};
    position: absolute;
    content: "";
    top: 0.6rem;
    right: -1rem;
    bottom: 0.6rem;
    width: 0.1rem;
    color: ${colors.white};
    background-color: ${colors.white};
  }

  a {
    ${navOneWhite};
    ${fontSizer(1.2, 1.2, 76.8, 150, 1.8)};
    padding: 0.5rem;
    color: ${props => (props.positionAbs ? colors.white : colors.greyBrown)};
    border-bottom: ${props =>
      props.currentPage
        ? "solid 3px rgba(197, 231, 222, 1)"
        : "solid 0px rgba(197, 231, 222, 1)"};
    text-transform: uppercase;
  }
`

const HeaderNavTopItem = ({ location, item, positionAbs, noPipe }) => {
  const itemSlug = createSlug(item.url)
  const itemSlugDisplay = itemSlug === "/" ? "" : itemSlug
  const currentPage = location === `/${itemSlugDisplay}`
  return (
    <ListItem
      currentPage={currentPage}
      positionAbs={positionAbs}
      noPipe={noPipe}
    >
      <AniLink cover direction="up" bg="#000" to={`/${itemSlugDisplay}`}>
        {item.title}
      </AniLink>
    </ListItem>
  )
}

export default HeaderNavTopItem
