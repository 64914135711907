import { colors } from "./index"
import { fonts, fontSizer } from "./index"

export const navOneBase = `
  ${fontSizer(1.6, 1.6, 76.8, 150, 1.8)};
  color: #464646;
  font-family: ${fonts.fontPrimary};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    color: ${colors.colorTertiary};
  }
`

export const navOneWhite = `
  ${navOneBase};
  color: ${colors.white};

  &:hover {
    color: ${colors.colorPrimary};
  }
`
