import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import { B2Black, colors, fonts } from "../../styles/helpers"

const getData = graphql`
  {
    footerOptions: wordpressAcfOptions {
      options {
        swb_theme_fsm_title
        swb_theme_footer_logo {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
        swb_theme_social_media {
          social_url
          social_type
        }
      }
    }
  }
`

const FooterContentSocialSection = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: calc(50%);
  }

  .socialTitle {
    h3 {
      ${B2Black};
    }
  }

  .socialIcons {
    width: 100%;
    margin: 2rem auto;
    a {
      color: ${colors.white};
      margin: 0;
    }

    a {
      display: inline-block;
      position: relative;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background-color: ${colors.greyBrown};
      margin-left: 1rem;

      &:first-of-type {
        margin-left: 0;
      }

      span {
        position: absolute;
        top: 0;
        left: 9999999rem;
      }

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${colors.white};
        font-family: ${fonts.fontAwesome};
        font-size: 1.25em;
      }

      &:hover {
        background-color: ${colors.colorSecondary};
      }
    }

    a.facebookIcon {
      &::after {
        content: "\f09a";
      }
    }

    a.twitterIcon {
      &::after {
        content: "\f099";
      }
    }
    a.instagramIcon {
      &::after {
        content: "\f16d";
      }
    }
    a.pintrestIcon {
      &::after {
        content: "\f231";
      }
    }
  }

  .socialLogo {
    width: 100%;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    &__wrapper {
      @media (min-width: 768px) {
        max-width: 21.1rem;
      }
    }
  }
`

const FooterContentSocial = () => {
  const data = useStaticQuery(getData)
  const title = data.footerOptions.options.swb_theme_fsm_title
  const footerLogo = data.footerOptions.options.swb_theme_footer_logo
  const socialIcons = data.footerOptions.options.swb_theme_social_media

  return (
    <FooterContentSocialSection>
      <div className="socialTitle">
        <h3>{title}</h3>
      </div>
      <div className="socialIcons">
        {socialIcons.map((icon, index) => {
          return (
            <a
              key={index}
              href={icon.social_url}
              className={`${icon.social_type}Icon`}
              target="_blank"
              rel="noopener noreferrer"
              title={`${icon.social_type} Account`}
            >
              <span>{icon.social_type}</span>
            </a>
          )
        })}
      </div>
      <div className="socialLogo">
        <div className="socialLogo__wrapper">
          <Img
            fluid={footerLogo.localFile.childImageSharp.fluid}
            alt={footerLogo.alt_text}
          />
        </div>
      </div>
    </FooterContentSocialSection>
  )
}

export default FooterContentSocial
