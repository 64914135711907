import React from "react"
import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"

import HeaderNavBottomItem from "./HeaderNavBottomItem"
import { buttonThree, colors } from "../../styles/helpers"

const HeaderNavBottomSection = styled.nav`
  width: 100%;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    @media (min-width: 1025px) {
      justify-content: flex-end;
    }

    .cta-btn {
      @media (min-width: 768px) {
        display: inline-block;
        margin-top: 1rem;
      }

      @media (min-width: 1200px) {
        margin-top: 0;
      }

      a {
        ${buttonThree};
        border-color: ${colors.white};
      }
    }
  }
`

const HeaderNavBottom = ({ location, botMenuItems, positionAbs }) => {
  return (
    <HeaderNavBottomSection>
      <ul>
        {botMenuItems.map((item, index) => (
          <HeaderNavBottomItem
            key={item.wordpress_id}
            item={item}
            index={index}
            positionAbs={positionAbs}
            total={botMenuItems.length}
            location={location}
          />
        ))}
        <li className="cta-btn">
          <AniLink
            cover
            direction="up"
            bg="#000"
            to="/community-life/amenities-map"
          >
            Community Plan
          </AniLink>
        </li>
      </ul>
    </HeaderNavBottomSection>
  )
}

export default HeaderNavBottom
