import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { B1Black, fontSizer, colors } from "../../styles/helpers"

const FooterCopySection = styled.div`
  width: 100%;
  text-align: center;

  p,
  a {
    ${B1Black};
    ${fontSizer(1.2, 1.4, 76.8, 150, 1.4)}
    margin: 0;
    padding: 0;
  }

  p.swbCred {
    margin-top: 0.25rem;
    ${fontSizer(1.4, 1.6, 76.8, 150, 1.4)}

    a {
      ${fontSizer(1.4, 1.6, 76.8, 150, 1.4)}
    }
  }

  a {
    &:hover {
      color: ${colors.colorShad};
    }
  }
`

const FooterCopy = () => {
  return (
    <FooterCopySection>
      <p>
        Cooper's Crossing © {new Date().getFullYear()} All Rights Reserved
        {" | "}
        <Link to={"/privacy-policy"}>Privacy Policy</Link>
        {" | "}
        <Link to={"/disclaimer"}>Disclaimer</Link>
      </p>
      <p className="swbCred">
        Designed &#38; developed by{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://switchbackcreative.ca"
        >
          Switchback Creative
        </a>
        . Built with ♡ and{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.gatsbyjs.org"
        >
          Gatsby
        </a>
        .
      </p>
    </FooterCopySection>
  )
}

export default FooterCopy
