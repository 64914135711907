import React from "react"
import styled from "styled-components"

import FooterContentSocial from "./FooterContentSocial"
import FooterContentNav from "./FooterContentNav"
import {} from "../../styles/helpers"

const FooterContentSection = styled.div`
  width: 100%;
  margin-bottom: 5rem;

  .wrapperInner {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const FooterContent = () => {
  return (
    <FooterContentSection>
      <div className="wrapperInner">
        <FooterContentSocial />
        <FooterContentNav />
      </div>
    </FooterContentSection>
  )
}

export default FooterContent
