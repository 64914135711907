import { colors } from "./index"
import { fonts, fontSizer } from "./index"

// Body copy ONE //
export const B1Base = `
  ${fontSizer(1.6, 1.8, 76.8, 150, 1.8)};
  font-family: ${fonts.fontPrimary};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
`

export const B1Black = `
  ${B1Base};
  color: ${colors.greyBrown};
`

export const B1White = `
  ${B1Base};
  color: ${colors.white};
`

export const B1Green = `
  ${B1Base};
  color: ${colors.colorSecondary};
`

// Body copy TWO //
export const B2Base = `
  ${fontSizer(1.8, 2.2, 76.8, 150, 2)};
  font-family: ${fonts.fontPrimary};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
`

export const B2Black = `
  ${B2Base};
  color: ${colors.greyBrown};
`
