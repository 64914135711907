import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import { B2Black, B1Black, fontSizer } from "../../styles/helpers"
import { createSlug } from "../../utils/helperFunc"

const getData = graphql`
  {
    quickLinks: wordpressWpApiMenusMenusItems(
      name: { eq: "Footer Quick Links" }
    ) {
      items {
        wordpress_id
        title
        object_slug
        target
        type
        url
      }
    }

    footOpt: wordpressAcfOptions {
      options {
        swb_theme_driving_dir
        swb_theme_driving_dir_link
      }
    }
  }
`

const FooterContentNavSection = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: calc(50%);
  }

  h3 {
    ${B2Black};
    margin-bottom: 1.5rem;
  }

  p {
    ${B1Black};
  }

  a {
    ${B1Black};
    ${fontSizer(1.2, 1.8, 76.8, 150, 1.6)}
    text-transform: uppercase;

    span {
      padding-right: 0.5rem;
    }
  }

  .navWrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
  }

  .quickLinks {
    display: none;
    width: 100%;
    text-align: right;

    @media (min-width: 768px) {
      display: block;
      width: calc(50% - 4rem);
      margin-right: 4rem;
    }

    @media (min-width: 1025px) {
      width: calc(50% - 6rem);
      margin-right: 6rem;
    }

    nav {
      a {
        display: block;
      }

      a[aria-current] {
        display: none;
      }
    }
  }

  .directions {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(50%);
    }

    p {
      ${B1Black};
      ${fontSizer(1.2, 1.6, 76.8, 150, 1.6)};
    }
  }
`

const FooterContentNav = () => {
  const data = useStaticQuery(getData)
  const links = data.quickLinks.items
  const dirLink = data.footOpt.options.swb_theme_driving_dir_link

  return (
    <FooterContentNavSection>
      <div className="navWrapper">
        <div className="quickLinks">
          <h3>Quick Links</h3>
          <nav>
            {links.length > 0 &&
              links.map((link, index) => {
                const slug = createSlug(link.url)
                return (
                  <Link key={index} to={`/${slug}`}>
                    <span>&gt;</span>
                    {link.title}
                  </Link>
                )
              })}
          </nav>
        </div>
        <div className="directions">
          <div>
            <h3>Driving Directions</h3>
          </div>
          <div>
            <a target="_blank" rel="noopener noreferrer" href={dirLink}>
              <span>&gt;</span>Google Maps
            </a>
          </div>
        </div>
      </div>
    </FooterContentNavSection>
  )
}

export default FooterContentNav
