import React from "react"
import styled from "styled-components"

import FooterContent from "./FooterContent"
import FooterCopy from "./FooterCopy"

import { colors, standardWrapper } from "../../styles/helpers"

const FooterSection = styled.footer`
  background-color: ${colors.colorAlt};

  .wrapper {
    ${standardWrapper};
  }
`

const Footer = () => {
  return (
    <FooterSection>
      <div className="wrapper">
        <FooterContent />
        <FooterCopy />
      </div>
    </FooterSection>
  )
}

export default Footer
