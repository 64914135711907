import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { colors } from "../../styles/helpers"
import MobileNavItemSub from "./MobileNavItemSub"

const NavItem = styled.li`
  position: relative;
  width: 100%;
  align-self: center;
  text-align: center;
  overflow: hidden;

  a {
    display: block;
    padding: 1.5rem 2rem;
    color: ${colors.white};
    font-size: 2rem;
    border-bottom: 0.15rem solid ${colors.black};

    &:hover {
      color: ${props => props.theme.colorSecondary};
    }
  }

  a[aria-current] {
    color: ${colors.white};
    background-color: ${colors.colorPrimary};
  }
`

const MobileNavItem = ({ item }) => {
  const subMenuReq =
    item.wordpress_children !== null
      ? item.wordpress_children.length > 0
        ? true
        : false
      : false

  const subMenu = subMenuReq ? (
    <MobileNavItemSub items={item.wordpress_children} />
  ) : null

  const slug = item.object_slug === "home" ? "" : item.object_slug

  return (
    <NavItem>
      <Link to={`/${slug}`}>{item.title}</Link>
      {subMenu}
    </NavItem>
  )
}

export default MobileNavItem
