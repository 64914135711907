const themeSize = {
  maxWidth: "1000px",
  bpTablet: "768px",
  bpDesksm: "1025px",
  bpDeskmd: "1200px",
  bpDesklg: "1600px",
  bpMax: "1900px",
}

export default themeSize
