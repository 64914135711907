import { colors } from "./index"
import { fonts, fontSizer } from "./index"

// Headline Styles #1 //
export const H1Base = `
    ${fontSizer(2.8, 4, 76.8, 150, 2.6)}
    font-family: ${fonts.fontSecondary};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
  `
export const H1White = `
  ${H1Base};
  color: ${colors.white};
`

export const H1WhiteBold = `
  ${H1Base};
  color: ${colors.white};
  font-weight: bold;
`

export const H1Green = `
  ${H1Base};
  color: ${colors.colorSecondary};
`

// Headline Styles #2 //
export const H2Base = `
    ${fontSizer(2.4, 3, 76.8, 150, 2.2)}
    font-family: ${fonts.fontSecondary};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
`
export const H2Black = `
    ${H2Base};
    color: ${colors.greyBrown};
`
export const H2Green = `
    ${H2Base};
    color: ${colors.colorSecondary};
`
export const H2White = `
    ${H2Base};
    color: ${colors.white};
`

// Headline Styles #3 //
export const H3Base = `
    ${fontSizer(2, 2.4, 76.8, 150, 2)}
    font-family: ${fonts.fontSecondary};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
`
export const H3Black = `
    ${H3Base};
    color: ${colors.greyBrown};
`
export const H3White = `
    ${H3Base};
    color: ${colors.white};
`

export const H3Green = `
    ${H3Base};
    color: ${colors.colorSecondary};
`

// Headline Styles #4 //
export const H4Base = `
    ${fontSizer(1.8, 2.2, 76.8, 160, 2)};
    font-family: ${fonts.fontSecondary};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
`
export const H4Black = `
    ${H4Base};
    color: ${colors.greyBrown};
`
export const H4White = `
    ${H4Base};
    color: ${colors.white};
`
