import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { fonts, fontSizer, colors } from "../../styles/helpers"

const MainNavSubMenuItemStyled = styled.li`
  width: 100%;

  a.sub-menu-link {
    display: block;
    width: 100%;
    padding: 1rem 0.5rem;
    border-bottom: 0.2rem solid ${colors.black};
    color: ${colors.white};
    font-size: 1.6rem;
  }
`

const MainNavSubMenuItem = ({
  currentPageSlug,
  objectSlug,
  title,
  location,
  url,
}) => {
  const isCurrentSub =
    `${currentPageSlug}/${objectSlug}` === location ||
    `${currentPageSlug}/${objectSlug}/` === location
      ? true
      : false

  const subSlug = url
    .split("/")
    .filter(part => {
      if (
        part !== "" &&
        part !== "http:" &&
        part !== "https:" &&
        part !== "localhost"
      )
        return part
    })
    .filter((slug, index) => {
      if (index !== 0) return slug
    })
    .join("/")

  return (
    <MainNavSubMenuItemStyled isCurrentSub={isCurrentSub}>
      <Link className="sub-menu-link" to={`/${subSlug}`}>
        {title}
      </Link>
    </MainNavSubMenuItemStyled>
  )
}

export default MainNavSubMenuItem
