import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import HeaderNavBottom from "./HeaderNavBottom"
import HeaderNavTop from "./HeaderNavTop"

const HeaderNavSection = styled.div`
  display: none;
  width: 100%;

  @media (min-width: 768px) {
    display: block;
  }

  @media (min-width: 1025px) {
    width: calc(80%);
  }
`

const getData = graphql`
  {
    mainMenu: wordpressWpApiMenusMenusItems(name: { eq: "Main Menu" }) {
      items {
        wordpress_id
        title
        object_slug
        target
        type
        url
        wordpress_children {
          wordpress_id
          title
          object_slug
          url
        }
      }
    }

    upperMenu: wordpressWpApiMenusMenusItems(name: { eq: "Upper Menu" }) {
      items {
        wordpress_id
        title
        object_slug
        target
        type
        url
      }
    }
  }
`

const HeaderNav = props => {
  const { positionAbs, location } = props
  const data = useStaticQuery(getData)
  const topMenuItems = data.upperMenu.items
  const botMenuItems = data.mainMenu.items
  return (
    <HeaderNavSection>
      <HeaderNavTop
        location={location}
        positionAbs={positionAbs}
        topMenuItems={topMenuItems}
      />
      <HeaderNavBottom
        location={location}
        positionAbs={positionAbs}
        botMenuItems={botMenuItems}
      />
    </HeaderNavSection>
  )
}

export default HeaderNav
